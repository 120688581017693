// import logo from './logo.svg';
import './App.css';
import UploadVideo from './views/UploadVideo';
import cookie from './utils/cookie';
import { tokenTest } from './utils/config';

//------------------------------------------------------------------------------
function App() {
  cookie.setCookie(cookie.keys.auth.token, tokenTest)
  
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <UploadVideo />
      </header>
    </div>
  );
}

export default App;
